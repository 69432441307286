<script>
  import {mapState, mapMutations, mapGetters} from 'vuex';
  import PostPage from '@/pages/BuyerPages/assignment_pages/b_PostInterpretation';
  import DangerousAssignmentEditMixin from '@/mixins/DangerousAssignmentEditMixin';

  export default {
    asyncData({store}) {
      let promisesArr = '';

      promisesArr = [...promisesArr, store.dispatch('PostingNotifierStore/preselectInterpretationEditStaticData')];

      return promisesArr ? Promise.all(promisesArr) : promisesArr;
    },
    extends: PostPage,
    mixins: [DangerousAssignmentEditMixin],
    data() {
      return {
        postAssignmentProgress: false,
      };
    },
    computed: {
      ...mapState('PostInterpretationStore', {
        shouldBeDisabledImportantFields: ({disabledImportantFields}) => disabledImportantFields,
        jobStatus: ({job}) => job.status
      }),
      ...mapGetters('OneAssignmentStore', [
        'storeJob'
      ]),
      ...mapGetters('UserInfoStore', [
        'userCanRegisterJobs'
      ]),
      jobId() { return this.$route.params.id; },
      job() { return this.summaryObj.job; },
      mainLinesTitle() {
        return this.$gettext('Assignment information');
      },
      submitBtnText() {
        return this.$gettext('Edit');
      },
      reviewModalBtnText() {
        return this.$gettext('Edit assignment');
      },
      disabledImportantFields() {
        return this.shouldBeDisabledImportantFields && !this.$isGodmode();
      }
    },
    methods: {
      ...mapMutations('PostInterpretationStore', [
        'setJobId'
      ]),
      editJob() {
        this.$store.commit('PostInterpretationStore/setTrackType', 'job_updated');
        return this.$store.dispatch('PostInterpretationStore/editJob');
      },
      sendInfoFunc(editFunc) {
        if (!this.checkDangerousFieldEdit()) {
          return editFunc ? editFunc() : this.editJob();
        } else {
          this.$store.dispatch('ModalStore/closeModal');
          return new Promise(() => {
            this.$store.commit('ModalStore/setModal', {
              component: 'dangerous-edit',
              width: 510,
              data: {
                context: this,
                data: {
                  description: this.$gettext('Changes will cancel your current assignment. We will automatically redirect you to the page where you can create a new assignment and rebook it.'),
                  text: this.$gettext('I confirm that these changes are necessary, and need to cancel and rebook with new information.')
                }
              }
            });
          });
        }
      },
      getJobToEdit() {
        this.postAssignmentProgress = true;
        this.$store.dispatch('PostInterpretationStore/getJob', this.$route.params.id).then(() => {
          this.postAssignmentProgress = false;
        }).catch(() => {
          this.postAssignmentProgress = false;
        });
      }
    },
    mounted() {
      this.setJobId(this.$route.params.id);
      if (!this.$store.state.existingEditJobInfo) {
        this.getJobToEdit();
      }
    },
  };
</script>
