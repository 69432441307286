  import {mapState, mapGetters} from 'vuex';
  import WSManager from '@/modules/ws_data_module';

  export default {
    computed: {
      ...mapState('PostInterpretationStore', {
        jobStatus: ({job}) => job.status
      }),
      ...mapState('PostingStore/SubjectStore', {
        subject: (state) => state.subject,
        category: (state) => state.category
      }),
      ...mapState('PostingStore/LanguagesStore', {
        alternativeLanguageTo: (state) => state.alternativeLanguageTo,
        languageFrom: (state) => state.languageFrom,
        languageTo: (state) => state.languageTo
      }),
      ...mapState('PostingStore/DateTimeStore', {
        startTime: (state) => state.startTime,
        finishTime: (state) => state.finishTime
      }),
      ...mapState('PostingStore/ProcessRequirementStore', {
        sessionTypeProcessing: (state) => state.sessionTypeProcessing,
        qualificationProcessing: (state) => state.qualificationProcessing,
        genderProcessing: (state) => state.genderProcessing
      }),
      ...mapState('PostingStore/QualificationStore', {
        qualification: (state) => state.qualification
      }),
      ...mapState('PostingStore/AssignmentTypeStore', {
        assignmentType: (state) => state.assignmentType,
        personName: (state) => state.contactAttributes.person.name,
        personPhone: (state) => state.contactAttributes.person.phone,
        addressExtra: (state) => state.contactAttributes.address.extra,
        videoPlatformId: (state) => state.videoPlatform.id
      }),
      ...mapState('PostingStore/GenderStore', {
        sex: (state) => state.sex
      }),
      ...mapGetters('OneAssignmentStore', [
        'storeJob'
      ]),
      ...mapState('FullAddressStore', [
        'line1',
        'line2',
        'county',
        'postcode',
        'city',
        'country',
        'longitude',
        'latitude'
      ]),
      ...mapGetters('UserInfoStore', [
        'userCanRegisterJobs'
      ]),
      jobId() { return this.$route.params.id; },
      storeAddress() { return this.storeJob.interpretationRequirement?.contactAttributes?.address; }
    },
    methods: {
      cancelAssignment(reason, withNotifications) {
        let params = {jobId: this.jobId, reason};
        params = withNotifications
          ? {...params, ...withNotifications}
          : this.userCanRegisterJobs
            ? {...params, withoutCharge: true}
            : params;
        this.$store.commit('OneAssignmentStore/startAssignmentProgress');
        this.$store.dispatch('OneAssignmentStore/sendCancel', params).then((data) => {
          this.$store.dispatch('ModalStore/closeModal');
          this.copyAssignment();
          WSManager.setDataFromSockets(this, data);
          this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
          this.$store.commit('OneAssignmentStore/setJobStatus', 'cancelled');
          this.$store.commit('OneAssignmentStore/setCanceller', data.canceller_name);
          if (reason.reason == 'dangerous_fields_edited') this.$store.commit('OneAssignmentStore/setCancelReason', reason.reason);
        }).catch(() => {
          this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
        });
      },
      copyAssignment() {
        this.saveNewDangerousData();
        const routeName = this.isSecretaryAssignment
          ? 'BuyerPostSecretaryInterpretation'
          : 'BuyerPostInterpretation';
        const routeObj = {
          name: routeName,
          query: {
            copy: this.jobId,
            dangerousEdit: true
          }
        };

        window.open(this.$router.resolve(this.$makeRoute(routeObj)).href, '_blank');
        this.$router.push(this.$makeRoute(
          {name: 'BuyerOneAssignment',
           params: {
             id: this.jobId
           }}
        ));
      },
      saveNewDangerousData() {
        const dangerousEdits = {};

        if (this.storeJob.interpretationRequirement?.languageToId != this.languageTo) {
          dangerousEdits.languageTo = this.languageTo;
        }
        if (this.storeJob.interpretationRequirement?.languageFromId != this.languageFrom) {
          dangerousEdits.languageFrom = this.languageFrom;
        }
        if (!this.$moment(this.storeJob.interpretationRequirement?.startTime).isSame(this.startTime, 'minute')) {
          dangerousEdits.startTime = this.startTime;
        }
        if (!this.$moment(this.storeJob.interpretationRequirement?.finishTime).isSame(this.finishTime, 'minute')) {
          dangerousEdits.finishTime = this.finishTime;
        }
        if (this.storeJob.interpretationRequirement?.sessionType != this.assignmentType) {
          dangerousEdits.assignmentType = this.assignmentType;
        }
        if (this.storeAddress?.city != this?.city) {
          dangerousEdits.city = this.city;
        }
        if (this.storeAddress?.country != this?.country) {
          dangerousEdits.country = this.country;
        }
        if (this.storeAddress?.county != this?.county) {
          dangerousEdits.county = this.county;
        }
        if (this.storeAddress?.latitude != this?.latitude) {
          dangerousEdits.latitude = this.latitude;
        }
        if (this.storeAddress?.longitude != this?.longitude) {
          dangerousEdits.longitude = this.longitude;
        }
        if (this.storeAddress?.line1 != this?.line1) {
          dangerousEdits.line1 = this.line1;
        }
        if (this.storeAddress?.postcode != this?.postcode) {
          dangerousEdits.postcode = this.postcode;
        }

        localStorage.setItem('dangerousFieldsEditedValues', JSON.stringify(dangerousEdits));
      },
      checkDangerousFieldEdit() {
        const storeJob = this.storeJob;
        const dangerEditStatuses = ['in_progress', 'accepted'];

        if (!dangerEditStatuses.includes(storeJob.status)) {
          return false;
        }

        const dangerousFields = {
          finishTime: this.$moment(storeJob.interpretationRequirement?.finishTime).format('hh:mm') != this.$moment(this.finishTime).format('hh:mm'),
          startTime: !this.$moment(storeJob.interpretationRequirement?.startTime).isSame(this.startTime, 'minute'),
          languageTo: storeJob.interpretationRequirement?.languageToId != this.languageTo,
          languageFrom: storeJob.interpretationRequirement?.languageFromId != this.languageFrom,
          assignmentType: storeJob.interpretationRequirement?.sessionType != this.assignmentType,
        };

        const dangerousFieldsInPersonAssignment = {
          city: this.storeAddress?.city != this?.city,
          country: this.storeAddress?.country != this?.country,
          county: this.storeAddress?.county != this?.county,
          latitude: this.storeAddress?.latitude != this?.latitude,
          longitude: this.storeAddress?.longitude != this?.longitude,
          line1: this.storeAddress?.line1 != this?.line1,
          postcode: this.storeAddress?.postcode != this?.postcode,
        };

        if (this.assignmentType === 'in_person') {
          return Object.values(dangerousFields).includes(true) || Object.values(dangerousFieldsInPersonAssignment).includes(true);
        }

        return Object.values(dangerousFields).includes(true);
      }
    }
  };
