<script>
  import PostInterpretationPrototype from '@/prototypes/PostInterpretationPrototype';

  export default {
    asyncData({store, route, moment}) {
      let promisesArr = '';

      if (!route.query.copy) {
        const preselectedStartTime = route.query.startTime ? moment(route.query.startTime) : '';
        const preselectedFinishTime = route.query.finishTime ? moment(route.query.finishTime) : '';
        const interpreterUid = route.query.interpreterid || '';

        store.dispatch('PostingNotifierStore/preselectInterpretationStaticData', {
          preselectedStartTime,
          preselectedFinishTime
        });
        if (interpreterUid && store.getters['UserInfoStore/userIsEnterpriseMember']) {
          const profileUserUid = store.state.EditProfileStore.profileData?.uid || store.state.EditProfileStore.profileData?.person?.uid;

          store.dispatch('PostingNotifierStore/preselectDirectInterpreter', interpreterUid);
          if (profileUserUid !== interpreterUid) {
            promisesArr = [...promisesArr, store.dispatch('PostingNotifierStore/getInterpreterProfile', {id: interpreterUid})];
          }
        }
      }
      if (store.getters['PostingNotifierStore/mainDataShouldBeFetched']) {
        promisesArr = [...promisesArr, store.dispatch('PostingNotifierStore/fetchMainData')];
      }
      // Filling the selectors for pricing templates
      if (store.state.TTAuthStore.godmode && Object.keys(store.state.PricingTemplatesStore.demanderTemplates).length === 0) {
        promisesArr = [...promisesArr, store.dispatch('PricingTemplatesStore/getTemplates', 'demander')];
      }
      if (store.state.TTAuthStore.godmode && Object.keys(store.state.PricingTemplatesStore.supplierTemplates).length === 0) {
        promisesArr = [...promisesArr, store.dispatch('PricingTemplatesStore/getTemplates', 'supplier')];
      }

      return promisesArr ? Promise.all(promisesArr) : '';
    },
    extends: PostInterpretationPrototype,
    computed: {
      mainLines() {
        return [{
          components: [{
            name: 'direct-interpreter-section',
            condition: this.showDirectInterpreterSection
          }]
        }, {
          components: [{
            name: 'language-section',
            params: {
              langToText: this.$gettext('Language'),
              disabledFields: this.disabledImportantFields,
              useSelectLanguagePopup: true
            },
            condition: !this.showDirectInterpreterSection
          }, {
            name: 'dialect-section',
            params: {
              showAssignmentHelper: true
            }
          }]
        }, {
          components: [{
            name: 'date-time-section',
            params: {
              disabledFields: this.disabledImportantFields,
              showAssignmentHelper: true
            }
          }]
        }, {
          components: [{
            name: 'booking-overtime-check',
          }]
        }, {
          components: [{
            name: 'edit-note-section',
            params: {
              jobStatus: this.jobStatus
            },
            condition: !!this.disabledImportantFields
          }]
        }, {
          components: [{
            name: 'subject-section',
            params: {
              showAssignmentHelper: true
            }
          }]
        }, {
          components: [{
            name: 'warning-section',
            condition: true
          }]
        }, {
          components: [{
            name: 'assignment-type-section',
            params: {
              disabledFields: this.disabledImportantFields,
              showAssignmentHelper: true
            }
          }]
        }];
      },
      footerTextParams() {
        return {
          dateTimes: [{
            startTime: this.fullStartTime,
            finishTime: this.fullFinishTime
          }],
          languageId: this.languageTo
        };
      },
    },
    methods: {
      getCopyOrReplacementJob() {
        const replacement = this.$route.query.replacement && this.$store.state.TTAuthStore.godmode;
        const dangerousEdit = this.$route.query.dangerousEdit;
        if (replacement) this.$store.dispatch('PostInterpretationStore/setJobCopiedBy', 'replacement');
        else if (dangerousEdit) this.$store.dispatch('PostInterpretationStore/setJobCopiedBy', 'dangerous_edit');
        let promisesArr = '';
        promisesArr = [...promisesArr, this.$store.dispatch('PostInterpretationStore/getCopyJob', {copyJobId: this.$route.query.copy, replacement})];
        promisesArr = [...promisesArr, this.$store.dispatch('OneAssignmentStore/getJobById', this.$route.query.copy)];
        this.postAssignmentProgress = true;
        Promise.all(promisesArr).then(() => {
          this.postAssignmentProgress = false;
        }).catch(() => {
          this.postAssignmentProgress = false;
        });
      }
    },
    mounted() {
      if (this.$route.query.copy) {
        this.getCopyOrReplacementJob();
      }
      if (!this.$cookieManager.getCookie('lastUsedType') || this.$cookieManager.getCookie('lastUsedType') !== 'interpretation') {
        this.$cookieManager.setCookie('lastUsedType', 'interpretation');
      }
      if (this.$route.query.dangerousEdit === 'true') {
        localStorage.setItem('unfinishedEditingJob', JSON.stringify({
          url: this.$route.fullPath,
          unfinished: true
        }));
      }
    }
  };
</script>
